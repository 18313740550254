<template>
    <el-container class="home-container">
        <!--头部区域：布局见：https://element.eleme.cn/#/zh-CN/component/container，要现在element.js里注册组件才能使用-->
        <el-header>
            <div class="head-left">
                <img src="../assets/nav_logo@3x.png" alt="">
            </div>
            <div class="edu-user">
                <el-popover placement="bottom" trigger="hover" :visible-arrow="false">
                    <div class="edu-user-list">
                        <!--            <li @click="toUpdatePwd()"><i class="el-icon-edit"></i>修改密码</li>-->
                        <li @click="logout()"><i class="el-icon-switch-button"></i>退出账号</li>
                    </div>
                    <div class="userName" slot="reference">
                        <img src="../assets/Group@3x.png" alt="">
                        <p class="edu-user-name"></p>
                    </div>
                </el-popover>
            </div>
        </el-header>
        <!--页面主体区域-->
        <el-container>
            <!--左侧区域-->
            <el-aside :width="isCollapse ? '64px' : '208px' "><!--和控制是否收缩一起使用，展开200, 收缩64-->
                <el-menu class="left-menu"
                    background-color="#001529" text-color="#fff" active-text-color="#fff"
                    unique-opened :collapse="isCollapse" :collapse-transition="false" router :default-active="isActivePath">
                    <!--展开关闭菜单的按钮-->
                    <el-menu-item index="/statistics" @click="saveNativePath('/statistics')" class="statistics">
                        <i class="el-icon-data-analysis" style="margin-right: 12px;"></i>
                        <span slot="title">收支统计</span>
                    </el-menu-item>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-monitor" style="margin-right: 12px;"></i>
                            <span>运营</span>
                        </template>
                        <el-menu-item index="/userManage" @click="saveNativePath('/userManage')">
                            <template slot="title">
                                <span>达人管理</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/teachCheck" @click="saveNativePath('/teachCheck')">
                            <template slot="title">
                                <span>达人审核</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/arenaManage" @click="saveNativePath('/arenaManage')">
                            <template slot="title">
                                <span>商家管理</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/coupon" @click="saveNativePath('/coupon')">
                            <template slot="title">
                                <span>优惠券</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/recomm" @click="saveNativePath('/recomm')">
                            <template slot="title">
                                <span>榜单管理</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/order" @click="saveNativePath('/order')">
                            <template slot="title">
                                <span>订单管理</span>
                            </template>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="3">
                        <template slot="title">
                            <i class="el-icon-setting" style="margin-right: 12px;"></i>
                            <span>基础设置</span>
                        </template>
<!--                        <el-menu-item index="/priceLevel" @click="saveNativePath('/priceLevel')">-->
<!--                            <template slot="title">-->
<!--                                <span>级别维护</span>-->
<!--                            </template>-->
<!--                        </el-menu-item>-->
<!--                        <el-menu-item index="/playManage" @click="saveNativePath('/playManage')">-->
<!--                            <template slot="title">-->
<!--                                <span>打法维护</span>-->
<!--                            </template>-->
<!--                        </el-menu-item>-->
                        <el-menu-item index="/playItem" @click="saveNativePath('/playItem')">
                            <template slot="title">
                                <span>技能维护</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/tripCost" @click="saveNativePath('/tripCost')">
                            <template slot="title">
                                <span>出⾏费⽤</span>
                            </template>
                        </el-menu-item>
                        <el-menu-item index="/areaManage" @click="saveNativePath('/areaManage')">
                            <template slot="title">
                                <span>区域维护</span>
                            </template>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <!--右侧内容区域-->
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Quill_editor_widget from "./common/quill_editor_widget";
export default {
    components: {Quill_editor_widget},
    data () {
        return {
            // 左侧菜单数据
            meunlist: [],
            // 是否展开左侧面板
            isCollapse: false,
            // 当前菜单选中状态，对应上面的:default-active
            isActivePath: '',
            userInfo: {realName: ''}////登录用户信息
        }
    },
    name: 'Home',
    created () {
        // let userInfo = window.localStorage.getItem('userInfo') //登录用户信息
        // this.userInfo = JSON.parse(userInfo);
        this.isActivePath = window.localStorage.getItem('activePath')// 页面加载时取上次选中的菜单
        if(this.isActivePath == '/statistics') {
            this.$router.push('/statistics')
        }
    },
    methods: {
        logout: function () { // 退出操作，清空token,调回登录页
            window.localStorage.clear()
            this.$router.push('/login')
        },
        toggleColleapse: function () {
            this.isCollapse = !this.isCollapse
        },
        saveNativePath: function (path) { // 点击二级菜单传路径赋值为当前激活菜单，并保存到sessionStorange中，刷新后还是展开这个菜单
            this.isActivePath = path
            window.localStorage.setItem('activePath', path)
        },
        toUpdatePwd() {
            this.$router.push('/password')
            this.saveNativePath('/password')
        }
    }
}
</script>

<style lang="less" scoped>
.el-header {
    background-color: #001529;
    display: flex; /*左右布局最简单用fiex布局*/
    justify-content: space-between; /*左右贴边布局，让图标和按钮在左右两侧*/
    padding-left: 0; /*让头像左侧贴着左边*/
    color: #fff;
    align-items: center; /*让按钮上下贴边变居中*/
    font-size: 20px;
    height: 48px !important;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0,21,41,0.12) !important;

    > div { /* 让文字和头像居中*/
        display: flex;
        align-items: center;

        span {
            margin-left: 15px;
        }
    }
}
.el-aside {
    background-color: #001529;
    .el-menu {
        border-right: none;
    }
}
.el-main {background-color: #f0f2fa;}
.home-container {height: 100%;}
.toggle-button {
    background-color: #1b3251;
    font-size: 10px;
    color: #fff;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.2em; /*文字间距*/
}
.edu-user{padding: 0 10px;margin-right:8px;display: flex;flex-direction: row;align-items: center;}
.edu-user img{width: 24px;height:24px;margin-right: 8px;background-color:#ececec;border-radius: 50%;object-fit:cover;}
.edu-user .userName{display:flex;align-items:center;padding:2px 0;}
.edu-term-list{max-height:240px;overflow-y:auto;}
.edu-user-list>li{list-style:none;line-height:40px;color:#595959;padding:0 9px;font-size:14px;cursor:pointer;}
.edu-user-list>li>i{margin-right:8px;color:#262626;font-size:16px;}
.edu-user-list>li:hover{background:#F5F7FA;}
.edu-user-name {font-size: 16px;}
.el-submenu__icon-arrow {margin-top: -27px;}
.size {font-size: 18px;margin-top: 5px;}

.head-left {height: 48px;width: 208px;background: #001529;}
.head-left img  {width: 128px;height: 32px;object-fit: cover;margin-top: 16px;margin-left: 36px;}
.left-menu {margin-top: 22px;}
</style>
<style>
.left-menu .el-menu-item, .el-submenu__title {height: 48px;line-height: 48px;}
.left-menu .el-menu .is-active {background-color: #1890FF !important;font-weight: 500;}
.el-menu .is-active {background-color: #1890FF !important;font-weight: 500;}
.el-menu-item {padding-left: 56px !important;}
.statistics {padding-left: 20px !important;}
</style>
