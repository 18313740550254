<template>
    <!--富文本编辑器组件-->
    <div>
        <quill-editor
            v-model="content"
            ref="myQuillEditor"
            :options="editorOption"
            @change="onEditorChange($event)">
        </quill-editor>

        <!-- 视频上传 -->
        <el-dialog title="视频上传" width="592px" :visible.sync="videoUploadTag" :close-on-click-modal="false" @close="closedialogVideoUpload" append-to-body>
            <el-tabs v-model="activeName">
                <el-tab-pane label="添加视频链接" name="first">
                    <el-input size="small" v-model="videoLink" placeholder="请输入视频链接" clearable></el-input>
                </el-tab-pane>
                <el-tab-pane label="本地上传" name="second">
                    <el-upload
                        class="avatar-uploader-videoWidget"
                        action="#"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="handleVideoChange"
                    >
                        <video v-if="videoUrl !=''" :src="videoUrl" class="video_avatar" controls="controls">您的浏览器不支持视频播放</video>
                        <i v-else-if="videoUrl ==''" class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p>只能上传mp4文件且文件不超过100MB</p>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="videoUploadTag = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addVideo" :loading="uploadVideoLoading">上 传</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import '../../assets/quill-font.css';
    import {Quill} from 'vue-quill-editor';
    import {ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend);
    const fontSizeStyle = Quill.import("attributors/style/size");
    fontSizeStyle.whitelist = ['12px', '14px', '16px', '20px', '24px', '36px'];
    Quill.register(fontSizeStyle, true);
    const toolOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'direction': 'rtl'}],
        [{'size': fontSizeStyle.whitelist}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}, {'background': []}],
        [{'align': []}],
        ['clean'],
        ['image', 'video']
    ];
    import { addQuillTitle } from '../../assets/quill-title';
    export default {
        props:{
            details: {
                type: String,
                default: "",
            },
        },
        data () {
            return {
                content: '',//文本编辑器内容
                pictureload:'',//图片是否加载完全
                editorOption: {
                    placeholder:'请在这里输入...',
                    modules: {
                        ImageExtend: {
                            name: 'file',  // 图片参数名
                            size: 3,  // 可选参数 图片大小，单位为M，1M = 1024kb
                            action: '/api/upload/image/upload',
                            response: (res) => {
                                return res.result;
                            },
                            headers: (xhr) => {
                                xhr.setRequestHeader('authorization',localStorage.getItem('token'))
                            },
                            sizeError: () => {
                                this.$message.error('图片不允许超过3M');
                            },  // 图片超过大小的回调
                            change: (xhr, formData) => {// 可选参数 选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
                                formData.append('type', 'school')
                            }
                        },
                        toolbar: {
                            container: toolOptions,  // 此次引入了全部工具栏
                            handlers: {
                                'image': function () {
                                    // 劫持原来的图片点击按钮事件
                                    QuillWatch.emit(this.quill.id)
                                },
                                // 视频点击事件
                                'video': () => {
                                    this.videoUploadTag = true;
                                }
                            }
                        }
                    }
                },
                videoUploadTag:false,//上传视频弹窗
                activeName:'first',
                videoLink:'',//视频链接
                videofile:'',//视频文件
                videoUrl:'',//视频路径
                uploadVideoLoading:false,//上传视频按钮loading
            }
        },
        mounted(){
            if(this.details){
                this.content = this.details;
            }
            addQuillTitle();
        },
        methods:{
            // 内容改变事件
            onEditorChange(editor){
                let pictureload = editor.text;
                this.pictureload =  pictureload.indexOf('[uploading]');
            },
            //关闭上传视频弹窗
            closedialogVideoUpload(){
                this.activeName = 'first';
                this.videoLink = '';
                this.videoUrl = '';
                this.uploadVideoLoading = false;
            },
            //上传视频change事件
            handleVideoChange({raw}){
                if (['video/mp4'].indexOf(raw.type) == -1) {
                    this.$message.error('请上传正确的视频格式');
                    return false;
                }
                let isLt100M = raw.size / 1024 / 1024 < 100;
                if (!isLt100M) {
                    return this.$message.error('上传文件大小不能超过 100MB');
                }
                let src = webkitURL.createObjectURL(raw);
                this.videoUrl = src;
                this.videofile = raw;
            },
            //保存上传视频
            async addVideo(){
                if(this.activeName == 'first'){
                    if (this.videoLink.length == 0) {
                        return this.$message.error('请输入视频链接');
                    }
                }else{
                    if(this.videofile == ''){
                        return this.$message.error('请选择上传的视频');
                    }
                    this.uploadVideoLoading = true;
                    let formData = new FormData();
                    formData.append('file',this.videofile);
                    await this.$http({
                        url: '/upload/image/upload',
                        data: formData,
                        headers: {
                            'Content-Type': "multipart/form-data; charset=UTF-8",
                        },
                        method:'post',
                        timeout: 300000,
                    }).then(res => {
                        let result = res.data;
                        if (result.code === 200) {
                            this.videoLink = result.result;
                        } else {
                            this.uploadVideoLoading = false;
                            this.$message.error(result.message);
                        }
                    }).catch(error => {
                        this.uploadVideoLoading = false;
                        this.$message.error('连接服务器失败!');
                    });
                }
                this.insertVideo();
            },
            //插入视频
            insertVideo(){
                //当编辑器中没有输入文本时，这里获取到的 range 为 null
                var range = this.$refs.myQuillEditor.quill.selection.savedRange;
                //视频插入在富文本中的位置
                var index = 0;
                if (range == null) {
                    index = 0;
                } else {
                    index = range.index;
                }
                //隐藏弹框
                this.videoUploadTag = false;
                //将视频链接插入到当前的富文本当中
                this.$refs.myQuillEditor.quill.insertEmbed(index, 'video', this.videoLink);
            }
        },
        watch:{
            activeName(n,o){
                if(n!=o){
                    this.videoLink = '';
                    this.videoUrl = '';
                    this.videofile = '';
                }
            },
            details(n,o){
                if(n!=o){
                    this.content = this.details;
                }
            }
        }
    }
</script>
<style scoped>
    .avatar-uploader-videoWidget{
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
        overflow: hidden;
    }
    .avatar-uploader-videoWidget .video_avatar{
        max-width:500px;
        height: 200px;
    }
</style>
<style>
    .avatar-uploader-videoWidget .el-upload{
        width:99%;
        height:98%;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader-videoWidget .el-upload .avatar-uploader-icon{
        padding-top:inherit;
    }
</style>
